import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import utils from 'utils';

const CartDrawer = () => {
    const navigate = useNavigate();
    const [items, setItems] = useState([]);

    const updateItems = () => {
        const currentItems = utils.getLocalStorage('cart_items');
        if (!currentItems) {
            return
        }
        setItems(Object.values(currentItems));
    }

    const navigateToProductPage = (location) => {
        navigate(location);
    }

    const handleRemoveFromCart = (item) => {
        const key = `${item.productName} - ${item.size}`;
        console.log(key);
        utils.removeDataStorage('cart_items', key);
    }

    const handleCloseDrawer = () => {
        const drawer = document.querySelector('.cart-drawer');
        drawer.classList.remove('open');
        drawer.classList.add('close');
    }

    const handleToCheckOut = () => {
        handleCloseDrawer();
        navigate('/check-out');
    }

    useEffect(() => {
        updateItems();
        window.addEventListener('cartUpdate', updateItems);
        return () => {
            window.removeEventListener('cartUpdate', updateItems);
        }
    }, [])

    return (
        <div className="cart-drawer">
            <div className="cart-drawer_header">
                <span onClick={handleCloseDrawer}>
                    X
                </span>
            </div>
            {
                items.length > 0 ? (
                    <div className="cart-drawer_content">
                        <div className="cart-drawer_show-products">
                            {
                                items.map(item => (
                                    <div className="cart-drawer_show-product">
                                        <div onClick={() => navigateToProductPage(item.location)} className="cart-drawer_show-product_image">
                                            <img src={item.image} />
                                        </div>
                                        <div className="cart-drawer_show-product_desciption">
                                            <h1 onClick={() => navigateToProductPage(item.location)} >{item.productName} - {item.size}</h1>
                                            <p>{item.quantity} x {utils.parsePrice(item.price)}</p>
                                        </div>
                                        <div className='cart-drawer_product-remove'>
                                            <button onClick={() => handleRemoveFromCart(item)}>x</button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        <div className='cart-drawer_total'>
                            <b>TẠM TÍNH: </b> {utils.parsePrice(items.reduce((sum, { price, quantity }) => sum + price * quantity, 0))}
                        </div>

                        <div className='cart-drawer_checkout-buttons'>
                            <button onClick={handleCloseDrawer}>
                                Tiếp tục mua hàng
                            </button>
                            <button onClick={handleToCheckOut}>
                                Thanh toán
                            </button>
                        </div>

                    </div>
                ) : <div className='cart-drawer_empty-text'>Không có sản phẩm nào trong giỏ hàng.</div>
            }
        </div>
    );
}

export default CartDrawer;


import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import utils from 'utils';
import BrandLogo from 'assets/TUMS-LOGO-B.png';
import ShoppingCartIcon from 'assets/free-shopping-cart-icon-3041-thumb.png';


const NavBar = ({ burgerOpen, setBurgerOpen }) => {
    const location = useLocation();
    const [cartCount, setCartCount] = useState(null);

    const handleBurger = () => {
        const burgerEl = document.querySelector('.nav-burger');
        burgerEl.classList.toggle('open');
        setBurgerOpen(!burgerOpen);
    }

    const updateCart = () => {
        const currentItems = utils.getLocalStorage('cart_items');
        if (!currentItems) {
            return
        }
        let quantity = 0;
        for (const productInfo of Object.values(currentItems)) {
            quantity += productInfo.quantity;
        }
        if (quantity !== cartCount) {
            setCartCount(quantity);
        }
    }

    const handleOpenCartDrawer = () => {
        if (location.pathname.includes('check-out')) {
            return
        }
        const drawerEl = document.querySelector('.cart-drawer');
        drawerEl.classList.add('open');
        drawerEl.classList.remove('close');
    }

    useEffect(() => {
        updateCart();
        window.addEventListener('cartUpdate', updateCart);
        return () => {
            window.removeEventListener('cartUpdate', updateCart);
        }
    }, [])

    return (
        <div className="nav-bar grid-col-span-9">
            <div className="nav-bar_fixed">
                <div className="nav-bar_holder">
                    <div className='nav-burger_holder'>
                        <div onClick={handleBurger} className="nav-burger">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>


                    <div className="logo-holder">
                        <div className="logo">
                            <Link to="/"><img src={BrandLogo} /></Link>
                        </div>
                    </div>
                    <div className="cart-holder">
                        <div onClick={handleOpenCartDrawer} className="cart">
                            <a><img src={ShoppingCartIcon} /></a>
                            {
                                cartCount ? <span className="cart-count">{cartCount}</span> : ""
                            }
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default NavBar;

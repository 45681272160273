import { useEffect } from "react";
import { NavLink, Navigate } from "react-router-dom";
import InstagramLogo from "assets/instagram-logo.png";
import FacebookLogo from "assets/facebook-logo.png";

const checkActive = ({ isActive }) => {
    return {
        color: isActive ? "red" : ""
    };
};

const Menu = ({ burgerOpen, setBurgerOpen }) => {

    const turnOffBurger = () => {
        setBurgerOpen(false);
        const menuBarEl = document.querySelector('.nav-burger');
        menuBarEl.classList.remove('open');
    }

    const turnOffBurgerWhenResize = () => {
        if (window.innerWidth >= 1050) {
            turnOffBurger();
        }
    }

    useEffect(() => {
        const menuBarEl = document.querySelector('.menu-bar');
        if (burgerOpen) {
            menuBarEl.classList.add('open');
        } else {
            menuBarEl.classList.remove('open');
        }

    }, [burgerOpen])

    useEffect(() => {
        window.addEventListener('resize', turnOffBurgerWhenResize);

        return () => window.removeEventListener('resize', turnOffBurgerWhenResize);
    }, [])

    return (
        <div className="menu-bar">
            <ul className="menu-bar_list">
                <li className="menu-bar_item">
                    <NavLink onClick={turnOffBurger} style={checkActive} to="/store">STORE</NavLink>
                </li>
                <li className="menu-bar_item">
                    <NavLink onClick={turnOffBurger} style={checkActive} to="/terms">TERMS</NavLink>
                </li>
                <li className="menu-bar_item">
                    <NavLink onClick={turnOffBurger} style={checkActive} to="/contact">CONTACT</NavLink>
                </li>
                <div className="social-icons">
                    <div className="instagram-logo">
                        <a href="https://www.instagram.com/" target="_blank">
                            <img src={InstagramLogo} />
                        </a>
                    </div>
                    <div className="facebook-logo">
                        <a href="https://www.facebook.com/" target="_blank">
                            <img src={FacebookLogo} />
                        </a>
                    </div>
                </div>
            </ul>

        </div>
    )
}

export default Menu;

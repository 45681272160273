import React, { Suspense, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import NavBar from 'components/NavBar';
import Menu from 'components/Menu';
import CartDrawer from 'components/CartDrawer';

const Products = React.lazy(() => import("pages/Products"));
const ProductDetail = React.lazy(() => import("pages/ProductDetail"));
const Terms = React.lazy(() => import("pages/Terms"));
const Contact = React.lazy(() => import("pages/Contact"));
const CheckOut = React.lazy(() => import("pages/CheckOut"));


function App() {

  const [burgerOpen, setBurgerOpen] = useState(false)

  return (
    <div className="main-layout">
      <BrowserRouter>
        <NavBar burgerOpen={burgerOpen} setBurgerOpen={setBurgerOpen} />
        <CartDrawer />
        <Menu burgerOpen={burgerOpen} setBurgerOpen={setBurgerOpen} />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Products />} />
            <Route path="/products/:productId" element={<ProductDetail />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/check-out" element={<CheckOut />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>

      </BrowserRouter>

    </div>
  );
}

export default App;

const utils = {
    isEmpty(obj) {
        return Object.keys(obj).length === 0;
    },
    parsePrice(rawPrice) {
        rawPrice = rawPrice.toString();
        if (rawPrice.length < 3) {
            return `${rawPrice} ₫`;
        }
        const charList = rawPrice.split("");
        const price = [];
        let count = 0;
        for (let i = charList.length - 1; i >= 0; i--) {
            if (count % 3 === 0 && i >= 0 && count > 0) {
                price.unshift(',')
            }
            price.unshift(charList[i]);
            count += 1;
        }

        return `${price.join('')} ₫`
    },
    signalCartUpdate() {
        window.dispatchEvent(new Event('cartUpdate'))
    },
    getLocalStorage(item) {
        const currentData = localStorage.getItem(item);
        if (currentData) {
            return JSON.parse(currentData);
        }
        return currentData;
    },
    setLocalStorage(item, data) {
        localStorage.setItem(item, JSON.stringify(data));
        utils.signalCartUpdate();
    },
    removeItemInStorage(item) {
        localStorage.remove(item);
        utils.signalCartUpdate();
    },
    removeDataStorage(item, key) {
        const currentData = utils.getLocalStorage(item);
        if (currentData) {
            delete currentData[key]
            utils.setLocalStorage(item, currentData);
            utils.signalCartUpdate();
        }
    },
    updateProductLocalStorage(item, key, data) {
        const currentData = utils.getLocalStorage(item);
        if (currentData) {
            if (currentData[key]) {
                const currentItem = currentData[key];
                if (currentItem.quantity + data.quantity <= data.available) {
                    utils.setLocalStorage(item, {
                        ...currentData, ...{
                            [key]: {
                                ...currentItem,
                                quantity: currentItem.quantity + data.quantity
                            }
                        }
                    });
                    utils.signalCartUpdate();
                } else {
                    return '* Vượt quá số lượng tối đa. Vui lòng kiểm tra lại giỏ hàng.'
                }
            } else {
                utils.setLocalStorage(item, { ...currentData, ...{ [key]: data } });
                utils.signalCartUpdate();
            }
        } else {
            utils.setLocalStorage(item, { [key]: data });
            utils.signalCartUpdate();
        }

        return 'OK'
    }
}

export default utils;
